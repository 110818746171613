.button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Outfit-medium;
    border-radius: 50px;
    padding: 15px 20px;

    .icon-container {
        margin-right: 0.5rem;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        .icon-title-button {
            margin: 0.5rem 0.35rem 0.4rem 0.35rem;
        }
    }
}
