.hero-carousel {
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;

    .carousel-element-first, .carousel-element-second  {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background-size: cover;
        overflow: hidden;
    }

    .carousel-element-first {
        background-image: url("../../images/pictures/mobile/la-main-sur-le-coeur-association-enfants-defavorises-mobile.jpg");
    }

    .carousel-element-second {
        background-image: url("../../images/pictures/mobile/la-main-sur-le-coeur-association-soins-et-scolarisation-mobile.jpg");
    }

    .title-container {
        width: 100%;
        height: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        background: {
            image: url("../../images/icons/coeur-textes.svg");
            size: cover;
            repeat: no-repeat;
        };
        background-position: bottom 0 right -5%;
        opacity: 0.85;
        margin-bottom: 1rem;
        
        .hero-text {
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            
            .title {
                width: 80%;
                font-family: Outfit-semibold;
                font-size: $title-hero-mobile;
                line-height: 2.5rem;
                color: $c-blue;
            }

            .subtitle {
                width: 90%;
                text-transform: uppercase;
                font-size: $subtitle-hero-mobile;
                line-height: 2rem;
                margin: 1rem 0;

                .subtitle-span {
                    color: $c-blue;
                }
            }

            .title-button {
                background-color: $c-red;
                color: $c-white;
                font-size: $text-mobile;
                padding: 10px 25px;

                .icon-container {
                    background-color: $c-white;
                }

                &:hover > div > img {
                    animation: pulse 2s infinite;
                }
            }
        }
    }

    .splide__arrow {
        display: none;
    }

    .splide__pagination {
        bottom: 3rem;
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/


@media (min-width: 599px) {

    .hero-carousel {
        .title-container {
            background-position: bottom 0 right -35%;

            .hero-text {
                width: 85%;
                
                .title {
                    width: 70%;
                    font-size: $title-hero-desktop;
                    line-height: 3rem;
                }
    
                .subtitle {
                    width: 60%;
                    font-size: $subtitle-hero-tablet;
                    line-height: 2.5rem;
                    margin: 2rem 0;
                }
    
                .title-button {
                    font-size: $text-tablet;
                    padding: 10px 25px;
                }
            }
        }
    }
}

@media (min-width: 899px) {
    .hero-carousel {
        .title-container {
            background-position: bottom 0 left -95%;
            background-size: contain;
            
            .hero-text {
                .title {
                    font-size: $title-hero-desktop-xl;
                    line-height: 3.5rem;
                }
    
                .subtitle {
                    font-size: $subtitle-hero-desktop;
                    line-height: 3rem;
                }
   
                .title-button {
                    font-size: $text-desktop;
                    padding: 15px 30px;
                }
            }
        }
    }
}

@media (min-width: 1024px) {
    .hero-carousel {
        .title-container {
            background-size: cover;
            background-position: bottom 40% right -50%;
        }
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/


@media (min-width: 1199px) {

    .hero-carousel {
        .title-container {
            height: 85%;
            align-items: flex-end;
            background-size: contain;
            background-position: bottom 0 right -30%;

            .hero-text {
                width: 30%;

                .title {
                    width: 95%;
                    font-size: $title-hero-desktop;
                }
    
                .subtitle {
                    width: 90%;
                    font-size: $subtitle-hero-tablet;
                    margin: 1rem 0;
                }
            }
        }

        .carousel-element-first {
            width: 100%;
            min-height: 100vh;
            background-image: url("../../images/pictures/desktop/la-main-sur-le-coeur-association-enfants-defavorises-desktop.jpg");
            background-size: cover;
        }
    
        .carousel-element-second {
            width: 100%;
            min-height: 100vh;
            background-image: url("../../images/pictures/desktop/la-main-sur-le-coeur-association-soins-et-scolarisation-desktop.jpg");
            background-size: cover;
        }
    
        .splide__arrow {
            display: none;
        }
    
        .splide__pagination {
            bottom: 6rem;
        }
    }
}

@media (min-width: 1399px) {
    .hero-carousel {
        .title-container {
            height: 75%;
            margin-bottom: 6rem;
            background-position: bottom 0 right -20%;

            .hero-text {
                width: 30%;

                .title {
                    width: 90%;
                    font-size: $title-hero-desktop-xl;
                }
    
                .subtitle {
                    font-size: $subtitle-hero-desktop;
                }

                .title-button {
                    font-size: $text-desktop-xl;
                    padding: 15px 35px;
                }
            }
        }
    }
}

@media (min-width: 1599px) {
    .hero-carousel {
        .title-container {
            .hero-text {
                width: 33%;

                .title {
                    width: 80%;
                }
    
                .subtitle {
                    width: 80%;
                }
            }
        }
    }
}

@media (min-width: 1920px) {
    .hero-carousel {
        .title-container {
            .hero-text {
                width: 27%;

                .subtitle {
                    width: 70%;
                }
            }
        }
    }
}