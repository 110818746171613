.header {
    width: 100vw;
    z-index: 999999;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .header-container {
        width: 85%;
        display: flex;
        justify-content: space-between;
        font-family: Outfit-semibold;
        margin: 1rem auto 0 auto;
    }

    .logo-container {
        min-width: 120px; 
        height: 120px; 
        display: block; 
        background-color: #fff;
        padding: 20px;
        z-index: 9999999999 !important;
        
        .logo {
            min-width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    
    .navigation-container {
        width: 30%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .navigation-items {
        display: none;
    }

    .navigation-items-mobile {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $c-white;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        .nav-item {
            display: flex;
            justify-content: center;
            width: auto;
            text-align: center;
            color: $c-black-text;
            text-transform: uppercase;
            font-size: 1.085rem;
            position: relative;

            &:nth-child(n)::after {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                position: absolute;
                top: 1.3rem;
            }

            &:nth-child(1)::after {
                background-color: $c-blue;
            }
    
            &:nth-child(2)::after {
                background-color: $c-red;
            }
    
            &:nth-child(3)::after {
                background-color: $c-yellow;
            }
    
            &:nth-child(4)::after {
                background-color: $c-sky-blue;
            }
        }

        .nav-item:not(:last-child) {
            margin-bottom: 3rem;
        }

        .menu-block-button-ctn {
            width: 100vw;
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;

            .block-button-give {
                color: $c-white;
                font-size: $text-mobile;
                padding: 5px 0;
            }
        }

        .invisible-menu-block-button-ctn {
            display: none;
        }

    }

    .burger-container {
        background-color: transparent;
    }

    .close-cross {
        color: $c-blue;
        font-family: Outfit-medium;
        font-size: 2rem;
        position: relative;
    }

    .header-button-ctn {
        display: none;
    }
}


//^---------------------------------  FIXED HEADER  ---------------------------------

.header-fixed {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;        
    position: fixed;
    top: 0;
    background-color: $c-white;
    box-shadow: 0px 5px 15px rgba(36, 62, 157, .25);
    padding: 0;
    transition: ease-in-out 0.3s;
    z-index: 999999;

    .header-container {
        margin: 0 auto;

        .logo-container {
            width: 150px;
            height: auto; 
            display: block; 
            padding: 0;
            margin: auto 0;

            .logo {
                width: 100%;
            }
        }
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
    .header {
        .header-container {
            width: 75%;
        }
    
        .logo-container {
            min-width: 150px; 
            height: 150px; 
        }
        
        .navigation-container {
            width: 20%;
        }

        .navigation-items-mobile {
            .menu-block-button-ctn {
                .block-button-give {
                    padding: 15px 0;
                }
            }
        }
    }

    .header-fixed {
        height: 90px;

        .header-container {
            .logo-container {
                width: 185px;
            }
        }
    }
}

@media (min-width: 899px) {
    .header {
        .logo-container {
            min-width: 175px; 
            height: 175px; 
        }
        
        .navigation-items-mobile {
            font-size: 1.5rem;
        }
    }
}

@media (min-width: 1024px) {
    .header {
        .logo-container {
            min-width: 175px; 
            height: 175px; 
        }

        .navigation-container {
            width: 15%;
        }
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
    .header {
        .header-container {
            width: 90%;
            height: 100px;
            display: flex;
            justify-content: space-between;
            font-family: Outfit-semibold;
            margin: 0 auto;
        }
    
        .navigation {
            height: 100%; 
            display: flex;
            margin: 0; 
        }
        
        .navigation-container {
            width: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .navigation-items {
            display: flex; 
            justify-content: center; 
            align-items: center;
            margin: 0;

            .nav-item {
                margin-left: 35px;
                color: $c-white;
                text-transform: uppercase;
                font-size: 1.085rem;
                position: relative;

                &:nth-child(n)::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 5px;
                    position: absolute;
                    top: 1.3rem;
                }

                &:nth-child(n)::before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 20%;
                    position: absolute;
                    bottom: -1.6rem;
                    transform: translateY(-1.3rem);
                    transition: all ease-in-out 0.3s;
                    z-index: -1;
                }

                &:nth-child(n):hover::before {
                    height: 200%;
                }

                &:nth-child(1)::after {
                    background-color: $c-blue;
                }

                &:nth-child(1):hover::before {
                    background-color: $c-blue;
                }

                &:nth-child(2)::after {
                    background-color: $c-red;
                }

                &:nth-child(2):hover::before {
                    background-color: $c-red;
                }

                &:nth-child(3)::after {
                    background-color: $c-yellow;
                }

                &:nth-child(3):hover::before {
                    background-color: $c-yellow;
                }

                &:nth-child(4)::after {
                    background-color: $c-sky-blue;
                }

                &:nth-child(4):hover::before {
                    background-color: $c-sky-blue;
                }
            }

            .menu-block-button-ctn, .invisible-menu-block-button-ctn {
                display: none;
            }
        }
    
        .navigation-items-mobile {
            display: none;
        }
    
        .burger-container {
            display: none;
        }

        .header-button-ctn {
            display: block;
        }
    
    }

    .header-fixed { 
        .header-container {
            .navigation-items {



                .nav-item:nth-child(n):hover {
                    color: $c-white;
                }

                .nav-item:nth-child(1) {
                    color: $c-blue;
                }

                .nav-item:nth-child(2) {
                    color: $c-red;
                }

                .nav-item:nth-child(3) {
                    color: $c-yellow;
                }

                .nav-item:nth-child(4) {
                    color: $c-sky-blue;
                }
            }
        }
    }

    .header-button {
        width: max-content; 
        height: max-content;
        display: flex !important; 
        flex-direction: row;
        justify-content: center;
        font-size: 1.1rem; 
        margin-top: 25px;
        font-family: Outfit-medium;
        background-color: $c-white; 
        color: $c-red;

        &:hover > div > img {
            animation: pulse 2s infinite;
        }
    }
    
    .header-button-fixed {
        width: max-content; 
        height: max-content;
        display: flex !important; 
        flex-direction: row;
        margin-top: 25px;
        font-size: 1rem; 
        background-color: $c-red;
        border: solid 2px $c-red;
        font-family: Outfit-medium;
        color: $c-white;
        transition: all ease-in-out 0.3s;

        .icon {
            margin-right: 0.5rem;
        }

        &:hover {
            background-color: $c-white;
            color: $c-red;

            .icon {
                animation: pulse 2s infinite;
            }
        }

 
    }
}

@media (min-width: 1399px) {
    .header {
        .header-container {
            width: 80%;
        }
    
        .navigation-items {
            .nav-item:not(:last-child) {
                margin-right: 0rem;
            }
        }
    }
}

@media (min-width: 1599px) {
    .header {
        .header-container {
            width: 75%;
        }
    }
}