@font-face {
    font-family: Poppins-light;
    src: url("../../fonts/Poppins/Poppins-Light.ttf");
}

@font-face {
    font-family: Poppins-semibold;
    src: url("../../fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: Outfit-light;
    src: url("../../fonts/Outfit/Outfit-Light.ttf");
}

@font-face {
    font-family: Outfit-medium;
    src: url("../../fonts/Outfit/Outfit-Medium.ttf");
}

@font-face {
    font-family: Outfit-semibold;
    src: url("../../fonts/Outfit/Outfit-SemiBold.ttf");
}

$c-pink: #D67A77;
$c-red: #CC3B18;
$c-yellow: #FCA854;
$c-cian: #8ACAD6;
$c-sky-blue: #5778D4;
$c-blue: #243E9D;

$c-light-pink: #F9ECEC;
$c-light-red: #F5D7D0;
$c-light-yellow: #FFEEDD;
$c-light-blue: #D3D8EB;

$c-black-title: #272727; // titre + intro Outfit (SemiBold + Light)
$c-black-text: #0C0C0C; // corps Poppins Light
$c-white: #ffffff;

$title-hero-mobile: 2rem;
$title-hero-tablet: 2.5rem;
$title-hero-desktop: 2.9rem;
$title-hero-desktop-xl: 3.2rem;

$subtitle-hero-mobile: 1.5rem;
$subtitle-hero-tablet: 1.9rem;
$subtitle-hero-desktop: 2.1rem;
$subtitle-hero-desktop-xl: 2.3rem;

$title-mobile: 1.5rem;
$title-tablet: 1.9rem;
$title-desktop: 2.2rem;
$title-desktop-xl: 2.8rem;

$subtitle-mobile: 1.3rem;
$subtitle-tablet: 1.5rem;
$subtitle-desktop: 1.7rem;
$subtitle-desktop-xl: 2rem;

$text-mobile: 1rem;
$text-tablet: 1.2rem;
$text-desktop: 1.4rem;
$text-desktop-xl: 1.5rem;
