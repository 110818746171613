.association-carousel {
    width: 100vw;
    padding: 60px 0;

    .carousel-element {
        padding: 1rem ;
        
        .element-ctn {
            width: 100%;
            box-shadow: 0px 5px 15px rgba(36, 62, 157, .25);
        }

        .image-container {
            height: 400px;
            width: 100%;
            margin: 0 auto;
            
            .image-member {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }
        
        .presentation-container {
            width: 100%;
            height: 400px;
            padding: 30px 35px;
            background-color: $c-cian;
            color: $c-white;
            line-height: 1.5rem;
            margin: 0 auto;
            font-size: $text-tablet;
            font-weight: 100;
        
            .name {
                margin-bottom: 35px;
                font-weight: 900;
                font-size: $subtitle-mobile;
            }
        
            .presentation {
                margin-bottom: 35px;
                line-height: 1.5rem;
            }

            .carousel-button {
                background-color: transparent;
                flex-direction: row-reverse;
                color: $c-white;
                text-align: left;
                padding: 0;
                font-family: Outfit-light;
                font-size: $text-tablet;
                font-weight: 100;
                border-bottom: solid 1px $c-white;
                border-radius: 0px;

                .icon-container {
                    margin-right: 0;
                    margin-left: 0.5rem;
                }

                &:hover > div {
                    animation: slide 1s infinite reverse;
                }
            }

            .marietou-carousel-button, .waris-carousel-button, .josiane-carousel-button {
                display: none;
            }
        }
    }


    .splide__pagination {
        bottom: -2rem;
    }

    .splide__pagination__page {
        border: 1px solid $c-blue;
        background-color: $c-blue;
        position: relative;
    }

    .splide__arrow {
        display: none;
    }
}

@media (min-width: 599px) {

    .association-carousel {
        width: 80%;
        margin: 0 auto;

        .carousel-element {
            .image-container {
                width: 100%;
            }
            
            .presentation-container {
                width: 100%;
                height: 300px;
            
                .name {
                    font-size: $subtitle-tablet;
                }
            
                .presentation {
                    line-height: 1.7rem;
                    font-size: $text-tablet;
                }
            }
        }
    
        .splide__arrow {
            display: flex;
            border: solid 2px $c-sky-blue;
            background-color: $c-white;
            font-size: 1.2rem;
            position: absolute;
            transition: 0.3s all ease-in-out;

            & > svg {
                fill: $c-blue;
            }

            &:hover {
                background-color: $c-sky-blue;
            }

            &:hover > svg {
                fill: $c-white
            }
        }
    
        .splide__arrow--prev {
            left: -2.5rem;
        }
    
        .splide__arrow--next {
            right: -2.5rem;
        }
    }
}

@media (min-width: 899px) {

    .association-carousel {
        width: 90%;
        margin: 0 auto;

        .carousel-element {
            .presentation-container {
                height: 420px;
            
                .name {
                    font-size: $subtitle-desktop;
                }
            
                .presentation {
                    line-height: 1.9rem;
                    font-size: $text-desktop;
                }
            }
        }
    
        .splide__arrow {
            font-size: 1.5rem;
        }
    }
}

@media (min-width: 1199px) {
    .association-carousel {
        width: 90%;
        margin: 0 auto;

        .carousel-element {
            .presentation-container {
                height: 380px;
            
                .name {
                    font-size: $subtitle-desktop;
                }
            
                .presentation {
                    line-height: 1.9rem;
                    font-size: $text-desktop;

                }

                .carousel-button {
                    font-size: $text-desktop;
                }
            }
        }
    
        .splide__arrow {
            font-size: 1.5rem;
        }
    }
}

@media (min-width: 1399px) {
    .association-carousel {
        width: 75%;

        .carousel-element {
            .presentation-container {
                height: 380px;
            
                .name {
                    font-size: $subtitle-desktop-xl;
                }
            
                .presentation {
                    line-height: 2.1rem;
                    font-size: $text-desktop-xl;
                }

                .carousel-button {
                    font-size: $text-desktop-xl;
                }
            }
        }

        .splide__arrow--prev {
            left: -5rem;
        }
    
        .splide__arrow--next {
            right: -5rem;
        }
    }
}

@media (min-width: 1920px) {
    .association-carousel {
        width: 70%;

        .carousel-element {
            .presentation-container {
                height: 350px;
            }
        }

        .splide__arrow--prev {
            left: -7rem;
        }
    
        .splide__arrow--next {
            right: -7rem;
        }
    }
}