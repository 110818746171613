.home-container {
    width: 100%;
    height: auto;
    overflow-x: hidden;

    //&------------------------------------------------------------------------------

    .hero-container {
        width: 100vw;
        height: 100vh;
        position: relative;
        overflow: hidden;
    
        .hero-content {
            width: 100%;
            height: 100%;
            position: relative;
        }
    }

    //&------------------------------------------------------------------------------
    
    .missions-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 120px;
        font-size: $text-mobile;
    
        .title-missions {
            width: 80%;
            font-size: $title-tablet;
            text-align: center;
        }
        
        .subtitle-missions {
            font-size: $subtitle-mobile;
            margin: 3rem 0;
            text-align: center;
        }
        
        .missions-content {
            display: flex;
            flex-direction: column;
        
            &-item {
                width: 300px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                margin: 2rem 0;
                position: relative;
    
                .missions-hero {
                    width: 90%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-evenly;
    
                    .img-container {
                        height: 80px; 
                        width: 80px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        border-radius: 50%;
    
                        .missions-img {
                            width: 60%;
                            height: 60%;
                            align-self: center;
                        }
                    }
            
                    .missions-subtitle {
                        font-size: $text-tablet;
                        line-height: 1.5rem;
                        margin: 2rem 0;
                        text-align: left;
                    }
                }
    
                .missions-hero-world {
                    flex-direction: row-reverse;
    
                    .missions-subtitle {
                        width: 70%;
                    }
                }
    
                .missions-text {
                    font-size: $text-mobile;
                    line-height: 1.4rem;
                }
        
                &::after {
                    content: "";
                    display: block;
                    width: 250px;
                    height: 5px;
                    background-color: #0C0C0C;
                    opacity: 0.15;
                    margin: 2rem 0;
                    border-radius: 5px;
                    position: absolute;
                    right: 2rem;
                    bottom: -4rem;
                }
        
                &:last-of-type::after {
                    display: none;
                }
            }
        }
    }

    //&------------------------------------------------------------------------------
    
    .support-container {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 60px 0 0 0;
        font-size: $text-desktop-xl;
    
        .support-hero {
            width: 100%;
            padding: 60px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
    
            .support-title-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                font-family: Outfit-semibold;
                margin-bottom: 35px;
    
                .support-title {
                    margin-left: 1rem;
                    font-size: $title-tablet;
                }
            }
    
            .support-text {
                width: 90%;
                text-align: center;
                font-size: $text-tablet;
                line-height: 1.5rem;
            }
        }
    
        .support-content-container {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    
        .support-content {
            width: 100vw;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 0.6rem;
    
            & > * {
                margin-bottom: 0.7rem;
            }
    
            & > *:last-of-type {
                margin-bottom: 0;
            }
    
            &:hover > div {
                cursor: pointer;
            }
    
            &:nth-child(1) > div > div {
                background: linear-gradient(180deg, rgba(36, 62, 157, .25) 0%, rgba(36, 62, 157, .25) 20%, rgba(36, 62, 157, .25) 100%), url("../../images/pictures/desktop/parrainer-un-enfant.jpg");
                background: {
                    size: cover;
                    repeat: no-repeat;
                    position: center;
                };
            }
    
            &:hover:nth-child(1) > div > div {
                background: linear-gradient(180deg, rgba(36, 62, 157, .65) 0%, rgba(36, 62, 157, .65) 20%, rgba(36, 62, 157, .65) 100%), url("../../images/pictures/desktop/parrainer-un-enfant.jpg");
                background: {
                    size: cover;
                    repeat: no-repeat;
                    position: center;
                };
            }
    
            &:nth-child(2) > div > div {
                background: linear-gradient(180deg, rgba(252, 168, 84, .25) 0%, rgba(252, 168, 84, .25) 20%, rgba(252, 168, 84, .25) 100%), url("../../images/pictures/desktop/s-engager.jpg");
                background: {
                    size: cover;
                    repeat: no-repeat;
                    position: center;
                };
            }
    
            &:hover:nth-child(2) > div > div {
                background: linear-gradient(180deg, rgba(252, 168, 84, .65) 0%, rgba(252, 168, 84, .65) 20%, rgba(252, 168, 84, .65) 100%), url("../../images/pictures/desktop/s-engager.jpg");
                background: {
                    size: cover;
                    repeat: no-repeat;
                    position: center;
                };
            }
    
            &:nth-child(3) > div > div {
                background: linear-gradient(180deg,  rgba(204, 59, 24, .25) 0%,  rgba(204, 59, 24, .25) 20%,  rgba(204, 59, 24, .25) 100%), url("../../images/pictures/desktop/faire-un-don.jpg");
                background: {
                    size: cover;
                    repeat: no-repeat;
                    position: center;
                };
            }
    
            &:hover:nth-child(3) > div > div {
                background: linear-gradient(180deg,  rgba(204, 59, 24, .65) 0%,  rgba(204, 59, 24, .65) 20%,  rgba(204, 59, 24, .65) 100%), url("../../images/pictures/desktop/faire-un-don.jpg");
                background: {
                    size: cover;
                    repeat: no-repeat;
                    position: center;
                };
            }
    
            .support-block {
                width: 100%;
                
    
    
                .img-container {
                    width: 100%;
                    height: 390px;
                    position: relative;
                    margin-bottom: 0.7rem;
    
                    .icon-container {
                        position: absolute;
                        bottom: -2rem;
                        left: 38%;
                        background-color: $c-white;
                        border-radius: 50%;
    
                        .icon {
                            padding: 0.7rem 0.8rem;
                        }
                    }
    
                    .block-button-text-img {
                        width: 90%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        font-family: Outfit-light;
                        font-size: 1.2rem;
                        line-height: 1.5rem;
                        color: $c-white;
                        margin: 0 auto 1rem auto;
                        transition: 0.3s ease-in-out;
                        transform: translateY(50px);
                        transition: 0.4s ease-in-out;
                        opacity: 0;
    
                        &:hover {
                            transform: translateY(0);
                            opacity: 1;
                        }
                
                        .span-btn {
                           margin-bottom: 0.7rem;
                        }
                    }
                }
    
                .support-button {
                    width: 100%;
                    height: 80px;
                    color: $c-white;
    
                    .support-icon {
                        display: none !important;
                    }
    
                    &:hover {
                        cursor: pointer !important;
                    }
                }
            }
        }
    }

    //&------------------------------------------------------------------------------
    
    .events-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 60px 0 0 0;
    
        .white-ctn {
            width: 100%;
            padding: 20px;
            display: flex;
            justify-content: center;
    
            .events-hero {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 1rem;
    
                .events-title {
                    font-family: Outfit-semibold;
                    font-size: $title-tablet;
                }

                .events-subtitle {
                    font-size: $text-tablet;
                    margin-top: 1rem;
                }
            }
        }
    
        .blue-ctn {
            width: 100%;
            height: 500px;
            padding: 60px 20px;
            color: $c-white;
            margin-top: 3rem;

            .loader {
                width: 100%;
                text-align: center;
                font-size: $text-tablet;
            }

            .event-ctn {
                width: 80%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                text-align: left;
                margin: 0 auto;

                .events-date {
                    font-family: Poppins-light;
                    font-size: $text-tablet;
                    line-height: 2rem;
                }
        
                .events-text {
                    margin: 35px 0;
                    line-height: 1.5rem;
                }
        
                .events-button {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    font-family: Outfit-medium;
                    border-radius: 50px;
                    padding: 10px 25px;
                    background-color: $c-white;
                    font-size: $text-tablet;
                    transition: 0.3s ease-in-out;
                    border: solid 2px $c-white;
        
                    .icon-container {
                        margin-left: 0.5rem;
                        border-radius: 50%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: $c-sky-blue;
                        padding: 0.3rem 0.55rem;
                    }

                    .not-now-event-ctn, .not-now-icon {
                        display: none;
                    }
        
                    &:hover {
                        background-color: $c-sky-blue;
                        color: $c-white;
                    }
        
                    &:hover > div {
                        animation: slide 1s infinite reverse;
                    }
                }
            }
    
        }
    }

    //&------------------------------------------------------------------------------
    
    .actu-container {
        width: 100%;
        padding: 60px 0 0 0;
        overflow-x: hidden;
    
        .actu-hero {
            .actu-title-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                font-family: Outfit-semibold;
                margin-bottom: 1rem;
        
                .actu-title {
                    margin-left: 1rem;
                    font-size: $title-tablet;
                    text-align: center;
                    line-height: 2.5rem;
                }
            }
        
            .actu-text {
                text-align: center;
                font-size: $text-tablet;
            }
        }
    
    
        .images-container {
            min-width: 100vw;
            max-width: 100vw;
            display: flex;
            flex-wrap: no-wrap;
            padding: 5px 0;
            margin: 2rem 0;
            overflow-x: scroll;
            
            & > * {
                height: 300px;
                box-shadow: lighten($c-black-text, 80%) 0px 0px 16px;
                margin-right: 1.5rem;
            }
    
            & > *:last-of-type {
                margin-right: 0;
            }
        }
    
        .actu-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-family: Outfit-medium;
            border-radius: 50px;
            padding: 10px 25px;
            background-color: $c-blue;
            font-size: $text-tablet;
            margin: 0 auto;
            transition: 0.3s ease-in-out;
            border: solid 2px $c-blue;
    
            .icon-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: $c-white;
                margin-right: 0.5rem;
                border-radius: 50%;
                padding: 0.3rem 0.7rem;
            }
    
            &:hover {
                background-color: $c-white;
                color: $c-blue;
            }
        }
    }

    //&------------------------------------------------------------------------------

    .newsletter-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        padding: 80px 0 40px 0;
    
        .white-ctn {
            width: 100%;
            padding: 0 20px;
            display: flex;
            justify-content: center;
    
            .newsletter-hero {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 1rem;
    
                .newsletter-title {
                    font-family: Outfit-medium;
                    font-size: $title-tablet;
                }
            }
        }
    
        .yellow-ctn {
            padding: 60px 20px;
            margin-top: 3rem;

            .newsletter-title {
                font-family: Outfit-medium;
                font-size: $title-mobile;
                line-height: 2rem;
            }
    
            .newsletter-text {
                margin: 35px 0;
                line-height: 1.5rem;
            }

            .newsletter-form {
                width: 100%;
                min-height: 90px;
                max-height: 90px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 1rem auto;

                .form-container {
                    width: 100%;
                    display: flex;
                }

                input {
                    width: 100%;
                    height: 60px;
                    font-family: Poppins-light;
                    font-size: 0.95rem;
                    padding: 0.8rem;
                    border: none;
                    border-radius: 50px 0 0 50px;
                }
    
                input::placeholder {
                    color: $c-black-title;
                }

                .newsletter-button {
                    height: 60px;
                    padding: 1rem;
                    font-size: 0.9rem;
                    font-family: Poppins-semibold;
                    color: $c-white;
                    border-radius: 0 50px 50px 0;
                    border: 2px solid $c-yellow;
                    transition: 0.3s all ease-in-out;

                    &:hover {
                        background-color: transparent;
                        color: $c-yellow;
                    }
        
                    .icon-container {
                        display: none !important;
                    }
                }
            }

            .error-message {
                width: 100%;
                color: $c-red;
                font-size: $text-mobile;
                font-family: Outfit-light;
                text-align: center;
                margin-top: 1rem;
            }

            .form-checkbox {
                width: 90%;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                align-items: center;
                margin: 2rem auto 0 auto;
    
                .checkbox {
                    display: none;
                }
    
                .checkbox-text {
                    position: relative;
                    line-height: 1.2rem;
                    margin-left: 2rem;
                    font-family: Outfit-light;
    
                    .span {
                        font-family: Outfit-semibold;
                        text-decoration: underline;
                    }
                }
    
                .checkbox + .checkbox-text::before {
                    content: '';
                    width: 30px;
                    height: 30px;
                    background-color: $c-white;
                    position: absolute;
                    left: -3rem;
                }
    
                .checkbox:checked + .checkbox-text::before {
                    width: 30px;
                    height: 30px;
                    background: url('../../images/icons/checkbox.svg');
                    background-size: cover;
                }
            }
        }

        .confirmation-message-container {
            width: 100%;
            color: $c-blue;
            font-size: $text-mobile;
            text-align: left;
    
            .message {
                width: 80%;
                margin: 0 auto;
                font-family: Poppins-semibold;
                line-height: 1.5rem;
            }

            .unsubscribtion-message {
                width: 80%;
                margin: 2rem auto;
                font-family: Poppins-light;
                line-height: 1.5rem;
            }
        }
    }

    //&------------------------------------------------------------------------------
    
    .asso-container {
        padding: 60px 0;
    
        .asso-title-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-family: Outfit-semibold;
    
            .asso-title {
                font-size: $title-tablet;
            }
    
            .asso-title-img {
                margin: -2.5rem 0 0 0.5rem;
            }
        }
    
    
    }

}

@media (min-width: 375px) {
    .home-container {
        .newsletter-container {
            .yellow-ctn {
                .newsletter-form {
                    input {
                        padding: 1rem 1.5rem;
                        font-size: $text-mobile;
                    }

                    .newsletter-button {
                        padding: 1rem 1.5rem;
                        font-size: $text-mobile;
                    }
                }
            }
        }
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {

    .home-container {
        .missions-container {
            padding-top: 120px;
        
            .title-missions {
                width: 80%;
                font-size: $title-desktop;
            }
            
            .subtitle-missions {
                font-size: $subtitle-tablet;
                margin: 3rem 0;
            }
            
            .missions-content {
                &-item {
                    width: 400px;
    
                    .missions-hero {
                        width: 100%;
                        
                        .img-container {
                            height: 90px; 
                            width: 90px;
                            border-radius: 50%;
                        }
                        
                        .missions-subtitle {
                            font-size: $text-desktop;
                            line-height: 1.5rem;
                            margin: 2rem 0;
                            text-align: left;
                        }
                    }
                    
                    .missions-text {
                        font-size: $text-tablet;
                        line-height: 1.6rem;
                    }
            
                    &::after {
                        width: 450px;
                        right: -1rem;
                        bottom: -4rem;
                    }
                }
            }
        }
    
        .support-container {
            font-size: $text-desktop-xl;
        
            .support-hero {
                .support-title-container {
                    margin-bottom: 35px;
        
                    .support-title {
                        font-size: $title-desktop;
                    }
                }
        
                .support-text {
                    width: 90%;
                    font-size: $text-desktop;
                    line-height: 2rem;
                }
            }
        
            .support-content {
                width: 100vw;
        
                .support-block {
                    .img-container {
                        width: 90%;
                        margin: 0 auto;
                        margin-bottom: 0.7rem;
        
                        .icon-container {
                            left: 44%;
        
                            .icon {
                                width: 80px;
                                height: 80px;
                            }
                        }
                    }
        
                    .support-button {
                        width: 90%;
                        margin: 0 auto;
                    }
                }
            }
        }
    
        .events-container {
            padding: 120px 0 60px 0;
        
            .white-ctn {
                .agenda-img {
                    width: 150px;
                    height: 150px;
                }
        
                .events-hero {
                    .events-title {
                        font-size: $title-desktop;
                    }
                }
            }
        
            .blue-ctn {
                height: 450px;

                .event-ctn {
                    .events-date {
                        font-size: $text-desktop;
                        line-height: 2rem;
                    }
            
                    .events-text {
                        font-size: $text-tablet;
                        line-height: 1.7rem;
                    }
            
                    .events-button {
                        .icon-container {
                            margin-left: 0.5rem;
                            border-radius: 50%;
                            padding: 0.5rem 0.75rem;
                        }
                    }
                }
            }
        }
    
        .actu-container {
            padding: 60px 0 0 0;
    
            .actu-hero {
                .actu-title-container {
                    margin-bottom: 1rem;
            
                    .actu-title {
                        font-size: $title-desktop;
                    }
                }
            
                .actu-text {
                    font-size: $text-desktop;
                }
            }
        
            .images-container {
                & > * {
                    height: 300px;
                    width: auto;
                }
            }
        
            .actu-button {
                padding: 12px 30px;
                font-size: $text-desktop;
        
                .icon-container {
                    margin-right: 1rem;
                    padding: 0.5rem 0.9rem;
                }
            }
        }

        .newsletter-container {
            padding: 120px 0 60px 0;
        
            .white-ctn {
                .newsletter-img {
                    width: 150px;
                    height: 150px;
                }
        
                .newsletter-hero {
                    .newsletter-title {
                        font-size: $title-desktop;
                    }
                }
            }
        
            .yellow-ctn {
                .newsletter-title {
                    width: 80%;
                    font-size: $title-desktop;
                    margin: 0 auto;
                }
        
                .newsletter-text {
                    width: 80%;
                    font-size: $text-tablet;
                    line-height: 1.7rem;
                    margin: 35px auto;
                }
    
                .newsletter-form {
                    width: 80%;

                    input {
                        font-size: $text-tablet;
                        padding: 1rem 2rem;
                    }
        
                    input::placeholder {
                        color: $c-black-title;
                    }
    
                    .newsletter-button {
                        padding: 0.5rem 2rem;
                        font-size: $text-tablet;
                    }
        
                    .error-message {
                        width: 100%;
                        color: $c-red;
                        font-size: $text-mobile;
                        font-family: Outfit-light;
                        text-align: center;
                        margin-top: 0.5rem;
                    }
                  
                }
    
                .form-checkbox {
                    width: 80%;
        
                    .checkbox + .checkbox-text::before {
                        left: -2.5rem;
                    }
                }
            }

            .confirmation-message-container {
                .message-bold, .message {
                    width: 80%;
                }
            }
        }
    
        .asso-container {
            padding: 60px 0;
        
            .asso-title-container {
                .asso-title {
                    font-size: $title-desktop;
                }
            }
        }
    }
}

@media (min-width: 899px) {

    .home-container {
        .missions-container {
            .title-missions {
                font-size: $title-desktop-xl;
            }
            
            .subtitle-missions {
                font-size: $subtitle-desktop;
            }
            
            .missions-content {
                &-item {
                    width: 400px;
    
                    .missions-hero {
                        width: 100%;
                        
                        .img-container {
                            height: 100px; 
                            width: 100px;
                        }
                        
                        .missions-subtitle {
                            font-size: $text-desktop-xl;
                            line-height: 1.8rem;
                        }
                    }
                    
                    .missions-text {
                        font-size: $text-desktop;
                        line-height: 2rem;
                    }
                }
            }
        }
    
        .support-container {
            .support-content {
                width: 100vw;
        
                .support-block {
                    .img-container {
                        width: 80%;
                    }
        
                    .support-button {
                        width: 80%;
                    }
                }
            }
        }
    
        .events-container {
            padding: 120px 0 60px 0;

            .white-ctn {
                padding: 0 20px 50px 20px;
            }

            .blue-ctn {
                height: 400px;
            }
        }
    
        .actu-container {
            padding: 60px 0;
    
            .actu-hero {
                .actu-title-container {
                    margin-bottom: 1rem;
            
                    .actu-title {
                        font-size: $title-desktop-xl;
                    }
                }
            
                .actu-text {
                    font-size: $text-desktop-xl;
                }
            }
        
            .images-container {
                & > * {
                    height: 300px;
                }
            }
        }

        .newsletter-container {
            .yellow-ctn {
                .newsletter-title {
                    width: 80%;
                    font-size: $title-desktop;
                    margin: 0 auto;
                }
        
                .newsletter-text {
                    width: 80%;
                    font-size: $text-tablet;
                    line-height: 1.7rem;
                    margin: 35px auto;
                }
    
                .newsletter-form {
                    width: 80%;

                    input {
                        font-size: $text-tablet;
                        padding: 1rem 2rem;
                    }
        
                    input::placeholder {
                        color: $c-black-title;
                    }
    
                    .newsletter-button {
                        font-size: $text-tablet;
                    }
        
                    .error-message {
                        width: 100%;
                        color: $c-red;
                        font-size: $text-mobile;
                        font-family: Outfit-light;
                        text-align: center;
                        margin-top: 0.5rem;
                    }
                  
                }
    
                .form-checkbox {
                    width: 78%;

                    .checkbox-text {
                        line-height: 1.5rem;
                    }
        
                    .checkbox + .checkbox-text::before {
                        left: -2.5rem;
                    }
                }
            }

            .confirmation-message-container {
                font-size: $text-tablet;
                
                .message {
                    line-height: 2rem;
                }
            }
        }
    
        .asso-container {
            padding: 60px 0;
        
            .asso-title-container {
                .asso-title {
                    font-size: $title-desktop-xl;
                }
            }
        }
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {

    .home-container {
        .missions-container {
            padding: 120px 0 60px 0;
        
            .subtitle-missions {
                margin: 3rem 0 5rem 0;
            }
            
            .missions-content {
                width: 90%;
                flex-direction: row;
                justify-content: space-around;
            
                &-item {
                    width: 90%;
                    margin: 0 4rem;
            
                    &::after {
                        width: 5px;
                        height: 200px;
                        margin: 2rem 0;
                        right: -4rem;
                        top: 2rem;
                    }
            
                    .missions-hero {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 2rem;
    
                        .img-container {
                            height: 110px; 
                            width: 110px;
                        }
                    }
            
                    .missions-hero-world {
                        .missions-subtitle {
                            width: 50%;
                        }
                    }
                }
            }
        }
        
        .support-container {
            width: 100%;
            padding: 60px 0;
            font-size: $text-desktop-xl;
        
            .support-hero {
                padding: 80px 0;
        
                .support-title-container {
                    margin-bottom: 50px;
                }
            }
    
            .support-content-container {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        
            .support-content {
                width: 33%;
        
                .support-block {
                    width: 100%;
    
                    .img-container {
                        width: 100%;
                        height: 390px;
        
                        .icon-container {
                            left: 40%;
                        }
                    }
        
                    .support-button {
                        width: 100%;
                    }
                }
            }
        }
        
        .events-container {
            flex-direction: row;
            padding: 60px 0;
        
            .white-ctn {
                flex-direction: column;
                width: 30%;
                padding: 4rem 0 4rem 4rem;
                    
                .events-hero {
                    .events-title {
                        width: 70%;
                        line-height: 2.5rem;
                        margin-top: 2rem;
                    }
                }
            }
        
            .blue-ctn {
                width: 70%;
                height: 450px;
                padding: 80px;

                .event-ctn {
                    width: 100%;
                
                    .events-date {
                        margin: 0;
                    }
            
                    .events-text {
                        margin: 35px 0;
                        font-size: $text-desktop;
                        line-height: 1.8rem;
                    }
            
                    .events-button {
                        font-size: $text-desktop;
                        padding: 12px 25px;
                        margin: 0;
                    }
                }
        
            }
        }

        .newsletter-container {
            flex-direction: row;
            padding: 60px 0;

            .white-ctn {
                width: 30%;
                padding: 0 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding-right: 5rem;
        
                .newsletter-hero {
                    width: 80%;
        
                    .newsletter-title {
                        font-size: $title-desktop;
                        text-align: right;
                        margin-top: 2rem;
                        line-height: 2.5rem;
                    }
                }
            }

            .yellow-ctn {
                width: 70%;

                .newsletter-text {
                    font-size: $text-desktop;
                    line-height: 1.8rem;
                }
    
                .newsletter-form {
                    input {
                        font-size: $text-desktop;
                    }
        
                    .newsletter-button {
                        font-size: $text-desktop;
                    }
                }
            }
        }
    }
}

@media (min-width: 1399px) {

    .home-container {
        .support-container {
            .support-content {
                .support-block {
                    .img-container {
                        .icon-container {
                            left: 43%;
                        }
                    }
                }
            }
        }
        
        .events-container {
            padding: 60px 0;

            .white-ctn {
                .events-hero {
                    margin-left: 2rem;
                    
                    .events-title {
                        font-size: $title-desktop-xl;
                    }
    
                    .events-subtitle {
                        font-size: $text-desktop;
                    }
                }
            }

            .blue-ctn {
                height: 400px;
                padding: 80px;
            }
        }
        
        .actu-container {
            margin: 0 auto;
    
            .images-container {
                & > * {
                    height: 400px;
                }
            }
        }

        .newsletter-container {
            .white-ctn {
                padding-right: 8rem;
        
                .newsletter-hero {
                    .newsletter-title {
                        font-size: $title-desktop-xl;
                    }
                }
            }

            .form-checkbox {
                .checkbox-text {
                    font-size: $text-tablet;
                }
            }

        }
    }
}

@media (min-width: 1599px) {

    .home-container {
        .newsletter-container {
            .white-ctn {
                padding-right: 10rem;
            }
        }
    }
}

@media (min-width: 1920px) {

    .home-container {
        .missions-container {
            width: 100%;
            padding: 120px 0 60px 0;
            font-size: $text-desktop-xl;
        
            .title-missions {
                font-size: $title-desktop-xl;
            }
            
            .subtitle-missions {
                font-size: $subtitle-desktop-xl;
                margin: 3rem 0 5rem 0;
            }
            
            .missions-content {
                width: 90%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
            
                &-item {
                    width: 90%;
                    margin: 0 4rem;
            
                    &::after {
                        width: 5px;
                        height: 200px;
                        margin: 2rem 0;
                        right: -4rem;
                        top: 2rem;
                    }
            
                    .missions-hero {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-evenly;
                        margin-bottom: 2rem;
    
                        .img-container {
                            height: 120px; 
                            width: 120px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            border-radius: 50%;
                        }
                
                        .missions-subtitle {
                            margin: 2rem 0;
                        }
                    }
            
                    .missions-hero-world {
                        .missions-subtitle {
                            width: 50%;
                        }
                    }
            
                    .missions-text {
                        font-size: $text-desktop;
                        line-height: 2rem;
                    }
                }
            }
        }
        
        .support-container {
            padding: 60px 0;
            font-size: $text-desktop-xl;
        
            .support-hero {
                padding: 80px 0;
        
                .support-title-container {
                    margin-bottom: 50px;
                }
            }
        
            .support-content {
                .support-block {
                    .img-container {
                        width: 100%;
                        height: 390px;
        
                        .icon-container {
                            left: 45%;
                        }
                    }
        
                    .support-button {
                        width: 100%;
                        height: 80px;
                    }
                }
            }
        }
        
        .events-container {
            padding: 60px 0;

            .white-ctn {
                padding: 4rem 0 4rem 8rem;
            }
        
            .blue-ctn {
                padding: 80px;

                .event-ctn {
                    .events-date {
                        margin: 0;
                    }
            
                    .events-text {
                        margin: 35px 0;
                    }
            
                    .events-button {
                        font-size: $text-tablet;
                        margin: 0;
            
                        .icon-container {
                            margin-left: 0.5rem;
                            padding: 0.5rem 0.75rem;
                        }
                    }
                }
        
            }
        }
    
        .actu-container {
            width: 90%;
        }

        .newsletter-container {
            .white-ctn {
                padding-right: 15rem;
            }
        }
    }
}