.footer-container {
    width: 100vw;
    height: auto;
    padding: 60px 0;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: $c-blue;
    color: $c-white;
    font-family: Poppins-light;

    & > * {
        margin-bottom: 3rem;
    }

    & > *:last-of-type {
        margin-bottom: 0;
    }

    .logo-fb-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .footer-fb-link {
            display: flex;
            margin-top: 1.5rem;

            .fb-footer {
                margin-left: 0.5rem;
            }
        }
    }

    .legale-container {
        & > * {
            display: block;
            margin-bottom: 0.5rem;
        }

        .privacy, .legales-mentions {
            text-decoration: underline;
            text-underline-position: under;
        }
    }

    .footer-nav-container {
        display: flex;
        flex-direction: column;

        .nav-title {
            font-family: Outfit-semibold;
        }

        .footer-navigation-items {
            display: flex;
            flex-direction: column;
            margin-top: 1rem;

            & > * {
                margin-bottom: 0.7rem;
            }
        }
    }

    .creation-container {
        width: 95%;
        display: flex;
        flex-direction: column;
        line-height: 1.5rem;

        .creation-title {
            margin-bottom: 1rem !important;
            font-family: Outfit-semibold;
        }

        & > * {
            margin-bottom: 1rem;
        }

        & > *:last-of-type {
            margin-bottom: 0;
        }
    }
}

.footer-container > div {
    flex-grow: 1;
}

.nav-title, .creation-title {
    text-transform: uppercase;
    font-family: Outfit-light;
    margin-bottom: 0.5rem;
}

.footer-container-no-margin-bottom {
    margin-bottom: 0 !important;
}

@media (min-width: 599px) {
    .footer-container {
        margin-bottom: 50px;
    }
}

@media (min-width: 899px) {
    .footer-container {
        margin-bottom: 60px;
    }
}

@media (min-width: 1199px) {
    .footer-container {
        padding: 80px 12.5%;
        margin-bottom: 70px;
        flex-direction: row;
        align-items: flex-start;

        .logo-fb-container, .legale-container, .footer-nav-container, .creation-container {
            width: 25%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
        }

    
        .logo-fb-container {
            .footer-fb-link {
                display: flex;
                margin-top: 1rem;
                transition: all ease-in-out 0.3s;
    
                .fb-footer {
                    margin-left: 0.5rem;
                }

                &:hover {
                    color: $c-cian;
                }
            }
        }
    
        .legale-container {
            .privacy, .legales-mentions {
                text-decoration: underline;
                text-underline-position: under;
                margin-bottom: 0.5rem;
                transition: all ease-in-out 0.3s;

                &:hover {
                    color: $c-cian;
                }
            }
        }
    
        .footer-nav-container {
            .footer-navigation-items {
                display: flex;
                flex-direction: column;
    
                &:nth-child(n):not(:last-child) {
                    margin-bottom: 0.5rem;
                }

                .item {
                    transition: all ease-in-out 0.3s;

                    &:hover {
                        color: $c-cian;
                    }
                }
            }
        }
    
        .creation-container {
            &:nth-child(n):not(:last-child) {
                margin-bottom: 0.5rem;
            }

            .credit {
                transition: all ease-in-out 0.3s;

                &:hover {
                    color: $c-cian;
                }
            }
        }
    }
}

@media (min-width: 1399px) {
    .footer-container {
        padding: 80px 100px;
    }
}

@media (min-width: 1599px) {
    .footer-container {
        padding: 80px 180px;
    }
}

@media (min-width: 1920px) {
    .footer-container {
        padding: 80px 250px;
    }
}