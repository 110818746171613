.volunteer-container {
    width: 100%;
    height: auto;
    overflow-x: hidden;

    .hero-img {
        width: 100vw;
        height: auto;
    }

    .title-container {
        width: 85%;
        margin-top: -3rem;
        pointer-events: none !important;
        position: relative;

        .hero-title {
            display: flex;
            align-items: center;
            padding: 1.5rem 2rem;
            flex-wrap: wrap;
            justify-content: center;
    
            font: {
                size: $subtitle-mobile;
            }
            
            .subtitle {
                margin-top: 0.5rem;
            }
        }
    }

    .volunteer-text {
        width: 100%;

        .subtitle {
            width: 85%;
            margin: 3rem auto;
            font-size: $text-mobile;
            line-height: 1.5rem;
        }

        .span {
            font-family: Outfit-semibold;
        }
    }

    .form-container {
        padding: 3rem 0;
        margin-bottom: 5rem;

        .form {
            width: 85%;
            margin: 0 auto;

            .grid {
                width: 100%;
                height: 100%;
                margin-bottom: 2rem;
            }

            .input-container {
                width: 100%;
                height: 65px;
                display: flex;
                flex-direction: column;
            }

            input, select {
                width: 100%;
                font-family: Poppins-light;
                font-size: $text-mobile;
                padding: 1rem;
                border: none;
            }

            input::placeholder, textarea::placeholder {
                color: $c-black-title;
            }

            .error-message {
                width: 100%;
                color: $c-red;
                font-size: $text-mobile;
                font-family: Outfit-light;
                text-align: center;
                margin-top: 0.5rem;
            }

            .situation-container {
                width: 100%;
                height: 65px;
                margin-bottom: 2rem;
                position: relative;

                select {
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    width: 100%;
                    padding: 1rem;
                    background-color: $c-white;
                    color: $c-black-title;
                    cursor: pointer;
                }

                &::after {
                    content: url("../../images/icons/suivant-bleu.svg");
                    position: absolute;
                    top: 0.8rem;
                    right: 1.5rem;
                    transform: rotate(90deg);
                    cursor: pointer;
                }

                select:focus {
                    outline: none;
                }

                select:-moz-focusring {
                    outline: none;
                }
            }

            .large-situation-container {
                height: auto;
                
                .other-container {
                    margin-top: 0.5rem;

                    input::placeholder {
                        color: lighten($c-black-title, 30%);
                    }
                }
            }

            .message-container {
                width: 100%;
                height: 380px;
                margin-bottom: 2rem;
            }

            .textarea {
                width: 100%;
                height: 350px;
                padding: 1rem;
                font-family: Poppins-light;
                font-size: $text-mobile;
                overflow-y: scroll;
                outline: none;
                resize: none;
                border: none;
            }
        }

        .form-mentions {
            width: 100%;
            margin: 1rem auto 0 auto;
            display: flex;
            justify-content: flex-end;
            font-family: Outfit-light;
            color: $c-black-title;
        }

        .form-checkbox {
            width: 90%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            margin: 2rem auto 4rem auto;

            .checkbox {
                display: none;
            }

            .checkbox-text {
                position: relative;
                line-height: 1.2rem;
                margin-left: 2rem;

                .span {
                    font-family: Outfit-semibold;
                    text-decoration: underline;
                }
            }

            .checkbox + .checkbox-text::before {
                content: '';
                width: 30px;
                height: 30px;
                background-color: $c-white;
                position: absolute;
                left: -3rem;
            }

            .checkbox:checked + .checkbox-text::before {
                width: 30px;
                height: 30px;
                background: url('../../images/icons/checkbox.svg');
                background-size: cover;
            }
        }

        .form-error-message {
            width: 100%;
            color: $c-red;
            font-size: $text-mobile;
            font-family: Outfit-semibold;
            text-align: center;
            margin-bottom: 2rem;
        }

        .form-button {
            font-size: $text-tablet;
            margin: 0 auto -5.5rem auto;
            border: 2px solid $c-yellow;
            transition: 0.3s all ease-in-out;

            &:hover {
                background-color: $c-white;
                color: $c-yellow;
            }

            .icon-container {
                display: none !important;
            }
        }

    }

    .confirmation-message-container {
        width: 100%;
        font-family: Poppins-light;

        .message-bold, .message {
            width: 90%;
            margin: 0 auto;
            text-align: center;
            color: $c-blue;
            font-size: $text-mobile;
            line-height: 1.5rem;
        }

        .message-bold {
            font-family: Poppins-semibold;
        }

        .message {
            margin-top: 2rem;

            .home-link {
                color: $c-sky-blue;
                text-decoration: underline;
                font-family: Poppins-semibold;
            }
        }
    }
}

@media (min-width: 375px) {
    .volunteer-container {
        .hero-img {
            height: 50vh;
        }
    
        .title-container {
            width: 75%;
        }
    }
    
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
    .volunteer-container {
        .hero-img {
            width: 150vw;
            height: 25vh;
        }

        .title-container {
            width: 65%;
    
            .hero-title {
                font: {
                    size: $subtitle-desktop;
                }
            }
        }
    
        .volunteer-text {
            .subtitle {
                font-size: $text-tablet;
                line-height: 1.7rem;
            }
        }
    
        .form-container {
            padding: 3rem 0;
            margin-bottom: 8rem;
    
            .form {
    
                input, select {
                    font-size: $text-tablet;
                }
    
                .grid {
                    width: 100%;
                    height: 100%;
                    margin-bottom: 2rem;
                }

                .situation-container {
                    &::after {
                        top: 1rem;
                        right: 1.5rem;
                    }
                }
    
                .select-situation {
                    width: 100%;
                }
    
                .textarea {
                    font-size: $text-tablet;
                }
            }
    
            .form-checkbox {
                width: 95%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 2rem auto;
    
                .checkbox + .checkbox-text::before {
                    left: -2.5rem;
                }
            }
    
            .form-button {
                font-size: $text-desktop;
            }
    
        }
    
        .confirmation-message-container {
            .message-bold, .message {
                width: 80%;
            }
        }
    }
}

@media (min-width: 899px) {
    .volunteer-container {
        .title-container {
            margin: -2.8rem 0 0 3rem;
    
            .hero-title {
                padding: 1.5rem 2.5rem;
                
                .subtitle {
                    margin-left: 0.5rem;
                    margin-top: 0;
                }
            }
        }

        .volunteer-text {
            .subtitle {
                width: 70%;
                margin: 5rem auto;
            }
        }
    
        .form-container {
            padding: 5rem 0;
            margin-bottom: 8rem;
            
            .form {
                width: 70%;
    
                input, select {
                    font-size: $text-tablet;
                }
    
                .grid {
                    width: 100%;
                    height: 100%;
                    margin-bottom: 2rem;
                }
    
                .select-situation {
                    width: 100%;
                }
    
                .textarea {
                    font-size: $text-tablet;
                }
            }
    
            .form-checkbox {
                width: 98%;
            }
    
            .form-button {
                font-size: $text-desktop;
                margin: 0 auto -7rem auto;
            }
    
        }
    
        .confirmation-message-container {
            .message-bold, .message {
                font-size: $text-tablet;
            }
        }
    }
}

@media (min-width: 1024px) {
    .volunteer-container {
        .form-container {
            .form {
                .input-container {
                    height: 65px;
                }
            }
        }
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
    .volunteer-container {
        .hero-img {
            width: 100vw;
            height: auto;
        }
        
        .title-container {
            width: 75%;
            margin: -2.8rem auto 0 auto;
    
            .hero-title {
                padding: 1.5rem 2.5rem;
            }
        }
    
        .volunteer-text {
            .subtitle {
                font-size: $text-desktop;
                line-height: 2rem;
            }
        }
    
        .form-container {
            padding: 4rem 0;
            margin-bottom: 10rem;
    
            .form {
                .input-container {
                    height: 78px;
                }
                
                input, select {
                    padding: 1.5rem;
                }

                .situation-container {
                    // height: 78px;
                    min-height: 78px;
                    max-height: 170px;
                    margin-bottom: 2rem;

                    select {
                        padding: 1.5rem;
                    }

                    &::after {
                        top: 1.5rem;
                        right: 1.5rem;
                    }


                }



                .message-container {
                    height: 280px;
                }

                .textarea {
                    height: 250px;
                    padding: 1.5rem;
                }
            }
    
            .form-mentions {
                margin: 0 auto;
            }

            .form-checkbox {
                margin: 2rem auto 6rem auto;
            }
    
            .form-button {
                font-size: $text-desktop;
                margin: 0 auto -5.5rem auto;
            }
        }
    
        .confirmation-message-container {
            .message-bold, .message {
                width: 60%;
                line-height: 2rem;
            }
        }
    }
}

@media (min-width: 1920px) {
    .volunteer-container {
        .form-container {
            .form-checkbox {
                .checkbox + .checkbox-text::before {
                    left: -2.7rem;
                }
            }
        }
    }
}
