.legales-container {
    width: 100vw;
    height: auto;
    overflow-x: hidden;

    .hero-img {
        width: 100vw;
        height: auto;
    }

    .title-container {
        width: 85%;
        margin-top: -3rem;
        pointer-events: none !important;
        position: relative;

        .hero-title {
            display: flex;
            align-items: center;
            padding: 1.5rem 2rem;
            flex-wrap: wrap;
            justify-content: center;
    
            font: {
                size: $subtitle-mobile;
            }
            
            .subtitle {
                margin-top: 0.5rem;
            }
        }
    }

    .legales-text {
        width: 100%;

        .subtitle {
            width: 85%;
            margin: 3rem auto;
            font-size: $text-mobile;
            line-height: 1.5rem;
        }

        .span {
            font-family: Outfit-semibold;
        }
    }
}

@media (min-width: 375px) {
    .legales-container {
        .hero-img {
            height: 50vh;
        }

        .title-container {
            width: 75%;
        }
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
    .legales-container {
        .hero-img {
            width: 150vw;
            height: 25vh;
        }

        .title-container {
            width: 65%;
    
            .hero-title {
                font: {
                    size: $subtitle-desktop;
                }
            }
        }
    
        .legales-text {
            .subtitle {
                font-size: $text-tablet;
                line-height: 1.7rem;
            }
        }
    }
}

@media (min-width: 899px) {
    .legales-container {
        .title-container {
            margin: -2.8rem 0 0 3rem;
    
            .hero-title {
                padding: 1.5rem 2.5rem;
                
                .subtitle {
                    margin-left: 0.5rem;
                    margin-top: 0;
                }
            }
        }

        .legales-text {
            .subtitle {
                width: 70%;
                margin: 5rem auto;
            }
        }
    }
}


/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
    .legales-container {
        .hero-img {
            width: 100vw;
            height: auto;
        }

        .title-container {
            width: 75%;
            margin: -2.8rem auto 0 auto;
    
            .hero-title {
                padding: 1.5rem 2.5rem;
            }
        }
    
        .legales-text {
            .subtitle {
                font-size: $text-desktop;
                line-height: 2rem;
            }
        }
    }
}

