    .action-container {
        display: none;
    }

    .action-container-mobile {
        width: 100%;
        position: absolute;
        bottom: -1rem;
        z-index: 99999999;
        
        .block-button-give {
            color: $c-white;
            font-size: $text-mobile;
            padding: 5px 0;
        }

        .block-button-sponsor, .block-button-committment {
            display: none;
        }
    }

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

    @media (min-width: 599px) {
        .action-container-mobile {
            .block-button {
                padding: 15px 0;
            }
        }
    }

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

    @media (min-width: 1199px) {
        .action-container {
            width: 100vw;
            height: 70px;
            display: flex;
            flex-direction: row;
            position: absolute;
            bottom: 0;
            z-index: 99999999;
    
            .block-button {
                color: $c-white;
                font-weight: 600;
            }
        }
    }