.scrollToTop-container {
    width: 80px;
    height: auto;
    position: fixed;
    bottom: 50px;
    right: 0;
    z-index: 999999;
    
    .scrollToTopBtn {
        width: 40px;
        height: 40px;
        background-color: $c-cian;
        border: 2px solid $c-cian;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        transition: all 0.3s ease-in-out;

        .icon {
            transform: rotate(-90deg);
        }

        &:hover {
            background-color: $c-white;
        }
    }

    .text {
        font-size: 0.8rem;
        font-family: Outfit-medium;
        margin: 0.5rem auto 0 auto;
        text-align: center;
        color: $c-blue;
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
    .scrollToTop-container {
        bottom: 75px;
        right: 5px;
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
    .scrollToTop-container {
        bottom: 80px;
        right: 5px;
    }
}
