.modal {
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #5778d4d7;
    backdrop-filter: blur(10px);
    z-index: 9999999999999;
    color: $c-white;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 50px 0;

    .modal-content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .image-member {
        width: 90%;
        display: flex;
        align-self: center;
    }

    .modal-text {
        width: 90%;
        margin: 0 auto;
        padding: 15px 0 5rem 0;

        .member-name {
            font-size: $text-desktop;
            margin-top: 1rem;
        }
    
        .moretext {
            line-height: 1.5rem;
            display: flex;
            align-self: center;
            padding: 15px 0;
            font-size: $text-mobile;

        }

        .heartIconMagnama {
            width: 35px;
        }

        .heartIconFatou {
            display: none;
        }
    }


    .modal-close {
        display: flex;
        align-items: center;
        background-color: transparent;
        font-size: 1.2rem;
        color: $c-white;
        margin-left: 1.5rem;
        position: absolute;
        top: 1rem;
        right: 1rem;
    
        .span-close {
          font-size: 1.2rem;
          margin-left: 0.5rem;
        }
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
    .modal {
        .image-member {
            width: 60%;
        }
        
        .modal-text {
            width: 80%;
    
            .member-name {
                font-size: $text-desktop-xl;
            }
        
            .moretext {
                font-size: $text-tablet;
                padding: 25px 0;
            }
        }
    
        .modal-close {
            font-size: 1.5rem;
        
            .span-close {
              font-size: 1.5rem;
            }
        }
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
    .modal {
        padding: 50px;

        .modal-content {
            align-items: center;
        }

        .image-member {
            width: 40%;
        }
        
        .modal-text {
            width: 50%;
            align-items: center;
    
            .member-name {
                font-size: $text-desktop-xl;
            }
        
            .moretext {
                font-size: $text-tablet;
                display: flex;
            }
        }
    
        .modal-close {
            font-size: 1.5rem;
            top: 2rem;
            right: 3rem;
        
            .span-close {
              font-size: 1.5rem;
            }
        }
    }
}

@media (min-width: 1599px) {
    .modal {
        overflow: hidden;
    }
}

@media (min-width: 1599px) {
    .modal {
        padding: 50px;

        .modal-content {
            width: 80%;
            margin: auto;
        }

        .modal-text {
            .member-name {
                font-size: $text-desktop-xl;
            }
        
            .moretext {
                font-size: $text-desktop;
                line-height: 1.8rem;
            }
        }
    }
}
