.block-button-text {
    display: none;
}

@media (min-width: 320px) {
    .block-button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $subtitle-mobile;
    }
    
    .block-button-icon {
        margin-right: 0.5rem;
    }
}

@media (min-width: 1199px) {

    .block-button-ctn {
        width: 33.3333333%;
        height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: 0.3s ease-in;
        padding: 0.5rem;
        
        &:hover {
            transform: translateY(-100px);
        }
    }

    .block-button {
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .block-button-text {
        width: 75%;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Outfit-light;
        font-size: 1.2rem;
        text-align: center;
        display: block;
        transition: 0.3s ease-in-out;
        color: $c-white;
        margin: 0.5rem auto;

        .span-btn {
           margin-bottom: 0.7rem;
        }
    }
    
    .block-button-icon {
        margin-right: 0.5rem;
    }
}

@media (min-width: 1599px) {

    .block-button-ctn {
        &:hover {
            transform: translateY(-90px);
        }
    }

    .block-button-text {
        margin: 1rem auto;
    }
    
}