.give-container {
    width: 100vw;
    height: auto;
    overflow-x: hidden;

    .hero-img {
        width: 100vw;
        height: auto;
    }

    .title-container {
        width: 85%;
        margin-top: -3rem;
        pointer-events: none !important;
        position: relative;

        .hero-title {
            display: flex;
            align-items: center;
            padding: 1.5rem 2rem;
            flex-wrap: wrap;
            justify-content: center;
    
            font: {
                size: $subtitle-mobile;
            }
            
            .subtitle {
                margin-top: 0.5rem;
            }
        }
    }

    .give-text {
        width: 100%;

        .subtitle {
            width: 85%;
            margin: 3rem auto;
            font-size: $text-mobile;
            line-height: 1.5rem;
        }

        .span {
            font-family: Outfit-semibold;
        }
    }

    .donate-button-container {
        width: 100%;
        height: 450px;
        padding: 3rem 0;
        margin-bottom: 10rem;

        .content {
            width: 90%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto;

            .paypal-donate-ctn, .helloasso-donate-ctn {
                width: 100%;
                height: 150px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                background-color: $c-white;
                padding: 1rem;

                .donate-button {
                    filter: grayscale(100%);
                    -webkit-filter: grayscale(100%);
                    -moz-filter: grayscale(100%);
                    transition: all 0.4s ease-in-out;
                    margin: 0;
                    padding: 0;

                    &:hover {
                        cursor: pointer;
                    }
                }

                &:hover > .donate-button, &:hover > form > .donate-button{
                    filter: grayscale(0%) !important;
                    -webkit-filter: grayscale(0%) !important;
                    -moz-filter: grayscale(0%) !important;
                }
            }

            .helloasso-donate-button {
                margin: auto 0 !important;

                .WidgetButton {
                    width: 100% !important;
                    height: 100% !important;
                }

                a {
                    width: 100% important;
                    height: 100% !important;
                }
            }
        }
    }
}

@media (min-width: 375px) {
    .give-container {
        .hero-img {
            height: 50vh;
        }

        .title-container {
            width: 75%;
        }

        .donate-button-container {
            .content {
                width: 80%;
            }
        }
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
    .give-container {
        .hero-img {
            width: 150vw;
            height: 25vh;
        }

        .title-container {
            width: 65%;
    
            .hero-title {
                font: {
                    size: $subtitle-desktop;
                }
            }
        }
    
        .give-text {
            .subtitle {
                font-size: $text-tablet;
                line-height: 1.7rem;
            }
        }

        .donate-button-container {
            height: 400px;
    
            .content {
                width: 70%;
    
                .paypal-donate-ctn, .helloasso-donate-ctn {
                    height: 120px;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 1rem 4rem;
                }
            }
        }
    }
}

@media (min-width: 899px) {
    .give-container {
        .title-container {
            margin: -2.8rem 0 0 3rem;
    
            .hero-title {
                padding: 1.5rem 2.5rem;
                
                .subtitle {
                    margin-left: 0.5rem;
                    margin-top: 0;
                }
            }
        }

        .give-text {
            .subtitle {
                width: 70%;
                margin: 5rem auto;
            }
        }

        .donate-button-container {
            height: 350px;
    
            .content {
                width: 70%;
    
                .paypal-donate-ctn, .helloasso-donate-ctn {
                    height: 100px;
                    padding: 1rem 5rem;
                }
            }
        }
    }
}

@media (min-width: 1024px) {
    .give-container {
        .form-container {
            .form {
                .input-container {
                    height: 65px;
                }
            }
        }
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
    .give-container {
        .hero-img {
            width: 100vw;
            height: auto;
        }

        .title-container {
            width: 75%;
            margin: -2.8rem auto 0 auto;
    
            .hero-title {
                padding: 1.5rem 2.5rem;
            }
        }
    
        .give-text {
            .subtitle {
                font-size: $text-desktop;
                line-height: 2rem;
            }
        }

        .donate-button-container {
            height: 300px;
    
            .content {
                width: 50%;
    
                .paypal-donate-ctn, .helloasso-donate-ctn {
                    height: 90px;
                }
    
                .form-button {
                    height: 50px;
                    font-size: $text-mobile;
                    margin: 0;
                    border: 3px solid $c-yellow;
                    transition: 0.3s all ease-in-out;
                    
                    .icon {
                        display: none;
                    }
                }
            }
        }
    }
}

