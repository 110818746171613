html {
    min-width: 100vw;
    max-width: 100vw;
}

body {
    width: 100%;
    font-family: Outfit-light;
    position: relative;
    overflow-x: hidden;
}

::selection {
    background-color: $c-sky-blue;
    color: $c-white;
}

.bg-white {
    background-color: $c-white;
}

.bg-yellow {
    background-color: $c-yellow;
}

.bg-yellow-light {
    background-color: $c-light-yellow;
}

.bg-blue {
    background-color: $c-blue;
}

.bg-red {
    background-color: $c-red;
}

.bg-pink {
    background-color: $c-pink;
}

.bg-light-red {
    background-color: $c-light-red;
}

.bg-light-blue {
    background-color: $c-light-blue;
}

.bg-sky-blue {
    background-color: $c-sky-blue;
}

.bg-cian {
    background-color: $c-cian;
}

.bg-light-pink {
    background-color: $c-light-pink;
}

.white-text {
    color: $c-white;
}

.red-text {
    color: $c-red;
}

.yellow-text {
    color: $c-yellow;
}

.blue-text {
    color: $c-blue;
}

.sky-blue-text {
    color: $c-sky-blue;
}

.cian-text {
    color: $c-cian;
}

.pink-text {
    color: $c-pink;
}

.bold-outfit {
    font-family: Outfit-semibold;
}

.bold-poppins {
    font-family: Poppins-semibold;
}

.bold {
    font-weight: 700;
}

a:hover{
    cursor: pointer !important;
}