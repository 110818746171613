.line {
    width: 35px;
    height: 4px;
    background-color: $c-white;
    border-radius: 5px;
    position: relative;

    &::before {
        content: '';
        width: 100%;
        height: 4px;
        background-color: $c-white;
        border-radius: 5px;
        position: absolute;
        bottom: 0.7rem;
        left: 0;
    }

    &::after {
        content: '';
        width: 100%;
        height: 4px;
        background-color: $c-white;
        border-radius: 5px;
        position: absolute;
        top: 0.7rem;
        right: 0;
    }
}

.line-fixed {
    width: 35px;
    height: 4px;
    background-color: $c-sky-blue;
    border-radius: 5px;
    position: relative;

    &::before {
        content: '';
        width: 100%;
        height: 4px;
        background-color: $c-sky-blue;
        border-radius: 5px;
        position: absolute;
        bottom: 0.7rem;
        left: 0;
    }

    &::after {
        content: '';
        width: 100%;
        height: 4px;
        background-color: $c-sky-blue;
        border-radius: 5px;
        position: absolute;
        top: 0.7rem;
        right: 0;
    }
}

.line-hidden {
    display: none;
}