@keyframes pulse {
    0% {
      transform: scale(1.2);
    }
    10% {
      transform: scale(1);
    }
    20% {
      transform: scale(1.2);
    }
    30% {
      transform: scale(1);
    }
    80% {
      transform: scale(1);
    }
}

@keyframes slide {
  0% {
      transform: translateX(0px);
  }
  50% {
      transform: translateX(3px);
  }
  100% {
      transform: translateX(0px);
  }
}
